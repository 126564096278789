import React, { useState, useEffect } from 'react';
import './styles.css';

import Card from '../../components/Card/index';

import api from '../../services/api';

export default function CardApresentation({ match }) {
  const [user, setUser] = useState({ name: '', document: '' });

  const date = new Date();
  const datetime = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} - ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}h`

  useEffect(() => {
    async function loadCard() {
      const response = await api.post('/auth', { document: match.params.doc });
      setUser(response.data);
    }
    loadCard();
  }, [match.params])

  return (
    <div>
      {user.document ? (
        <div className="card-page-container">
          <h5>Apresente este cartão virtual ao estabelecimento parceiro e você terá seu benefício na hora!</h5>
          <Card name={user.name} document={user.document} />
          <p>Consulta realizada em <strong>{datetime}</strong></p>
        </div>
      ) : (
        <div className="card-page-container">
          <h5>Usuário não autorizado.</h5>
        </div >
      )}
    </div>
  )
}